var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.invoice != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [
            _vm._v(" Invoice no : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.invoice.invoice_no) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Client request invoice : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.invoice.request_invoice) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Send date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.invoice.send_date != null
                      ? _vm.convertTimeZone(this.invoice.send_date)
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Invoice to name : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.invoice.requester_name != null
                      ? this.invoice.requester_name
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Invoice to address : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.invoice.requester_address != null
                      ? this.invoice.requester_address
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }