var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.specialRemark != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [
            _vm._v(" Title : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.specialRemark.title) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Remark : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.specialRemark.remark) + " "),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }