var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.funnel != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [
            _vm._v(" Funnel Event Date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.funnel.funnel_event_date != null
                      ? _vm.convertTimeZone(this.funnel.funnel_event_date)
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", {}, [
            _vm._v(" Lead Source : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.funnel.lead_source) + " "),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }