var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.data != null
      ? _c(
          "div",
          { staticClass: "my-3" },
          _vm._l(this.data, function (item, key) {
            return _c("div", { key: key }, [
              _vm._v(" " + _vm._s(key) + " : "),
              _c("span", { staticClass: "font-weight-bold" }, [
                _vm._v(" " + _vm._s(item) + " "),
              ]),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }