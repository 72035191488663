var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _vm.api.isLoading
        ? _c("v-skeleton-loader", {
            ref: "skeleton",
            attrs: { type: "card-avatar" },
          })
        : _vm._e(),
      !_vm.api.isLoading && _vm.logs != null
        ? _c(
            "div",
            { staticClass: "d-flex justify-start" },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-3",
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      this$1.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      !_vm.api.isLoading && _vm.logs != null
        ? _c(
            "v-card",
            { attrs: { color: "secondary" } },
            [
              _c("div", { staticClass: "text-h5 pa-5 white--text" }, [
                _vm._v(" Logs "),
              ]),
              _c("p", { staticClass: "px-5 white--text" }),
              _c(
                "v-list",
                _vm._l(_vm.logs, function (item, index) {
                  return _c(
                    "v-list-item",
                    { key: index },
                    [
                      _c(
                        "v-container",
                        [
                          item.log_class == "cancelCompanySubscription"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class == "createCompanyAAEBatch" ||
                          item.log_class === "createSubscriptionHistoryBatch" ||
                          item.log_class === "createCompanySalesBatch" ||
                          item.log_class === "createCompanyHRDCByBatch" ||
                          item.log_class === "createSpecialRemarkBatch" ||
                          item.log_class === "createCompanyFunnelBatch"
                            ? _c("v-card", [
                                _c(
                                  "div",
                                  { staticClass: "pa-4" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start text-h6",
                                      },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start mb-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.created_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("ComponentLogsJson", {
                                      attrs: {
                                        data: JSON.parse(item.description),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanyFullInformation" ||
                          item.log_class == "updateClient" ||
                          item.log_class == "createUserOnboardingBatch"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6 mb-5",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h7 mt-3 font-weight-bold",
                                        },
                                        [_vm._v(" Before : ")]
                                      ),
                                      item.log_class ===
                                      "updateCompanyFullInformation"
                                        ? _c("ComponentLogsCompanyUpdate", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .Old,
                                            },
                                          })
                                        : _vm._e(),
                                      item.log_class === "updateClient"
                                        ? _c("ComponentLogsUserUpdate", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .Old,
                                            },
                                          })
                                        : _vm._e(),
                                      item.log_class ===
                                      "createUserOnboardingBatch"
                                        ? _c("ComponentLogsJson", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .Old,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "text-h7 mt-3 font-weight-bold",
                                        },
                                        [_vm._v(" After : ")]
                                      ),
                                      item.log_class ===
                                      "updateCompanyFullInformation"
                                        ? _c("ComponentLogsCompanyUpdate", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .New,
                                            },
                                          })
                                        : _vm._e(),
                                      item.log_class === "updateClient"
                                        ? _c("ComponentLogsUserUpdate", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .New,
                                            },
                                          })
                                        : _vm._e(),
                                      item.log_class ===
                                      "createUserOnboardingBatch"
                                        ? _c("ComponentLogsJson", {
                                            attrs: {
                                              data: JSON.parse(item.description)
                                                .New,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "VisionFormRenewal" ||
                          item.log_class === "VisionFormPaymentStatus"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " + _vm._s(item.description) + " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "removePaymentDetail"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            item.created_at.split(" ")[0]
                                          ) +
                                          " " +
                                          _vm._s(
                                            item.created_at.split(" ")[1]
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start font-weight-bold mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " Credit card detail : " +
                                          _vm._s(item.description) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "removeDropDown"
                            ? _c("v-card", [
                                _c(
                                  "div",
                                  { staticClass: "pa-4" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start text-h6",
                                      },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start mb-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.created_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c("ComponentLogsDropdownOptions", {
                                      attrs: {
                                        data: JSON.parse(item.description),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          item.log_class === "updateSubscriptionHistory" ||
                          item.log_class === "updateCompaniesInvoice" ||
                          item.log_class === "updateFinanceSurvey" ||
                          item.log_class === "updateDropdownKeys" ||
                          item.log_class === "updateRemark"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class ===
                                    "updateSubscriptionHistory"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "ComponentlogsSubscriptionHistory",
                                              {
                                                attrs: {
                                                  data: JSON.parse(
                                                    item.description
                                                  ).Old,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateDropdownKeys"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsDropdownOptions", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateCompaniesInvoice"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyInvoice", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateFinanceSurvey"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyFinance", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateRemark"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsSpecialRemark", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class ===
                                    "updateSubscriptionHistory"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "ComponentlogsSubscriptionHistory",
                                              {
                                                attrs: {
                                                  data: JSON.parse(
                                                    item.description
                                                  ).New,
                                                },
                                              }
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateDropdownKeys"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsDropdownOptions", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateCompaniesInvoice"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyInvoice", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateFinanceSurvey"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyFinance", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    item.log_class === "updateRemark"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsSpecialRemark", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class ===
                            "createSubscriptionHisotryByCompany" ||
                          item.log_class === "createCompaniesInvoice" ||
                          item.log_class === "addFinanceSurvey" ||
                          item.log_class === "addDropDown"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class === "addDropDown"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsDropdownOptions", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.log_class ===
                                  "createSubscriptionHisotryByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "ComponentlogsSubscriptionHistory",
                                            {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ),
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.log_class === "createCompaniesInvoice"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyInvoice", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.log_class === "addFinanceSurvey"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyFinance", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "storeRemark"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("ComponentLogsSpecialRemark", {
                                        attrs: {
                                          data: JSON.parse(item.description),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanyRefund"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class === "updateCompanyRefund"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyRefund", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class === "updateCompanyRefund"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyRefund", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "createCompanyRefundByCompany"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class ===
                                  "createCompanyRefundByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyRefund", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanySales"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class === "updateCompanySales"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanySales", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class === "updateCompanySales"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanySales", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "createCompanySalesByCompany"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class ===
                                  "createCompanySalesByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanySales", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanyHRDC"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class === "updateCompanyHRDC"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyHRDC", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class === "updateCompanyHRDC"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyHRDC", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "createCompanyHRDCByCompany"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class ===
                                  "createCompanyHRDCByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyHRDC", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanyAAE"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class === "updateCompanyAAE"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyAAE", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class === "updateCompanyAAE"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyAAE", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "createCompanyAAEByCompany"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class === "createCompanyAAEByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyAAE", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "updateCompanyFunnel"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" Before ")]
                                    ),
                                    item.log_class === "updateCompanyFunnel"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyFunnel", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).Old,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _c("div", [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "text-h7 mt-3 font-weight-bold",
                                      },
                                      [_vm._v(" After ")]
                                    ),
                                    item.log_class === "updateCompanyFunnel"
                                      ? _c(
                                          "div",
                                          [
                                            _c("ComponentLogsCompanyFunnel", {
                                              attrs: {
                                                data: JSON.parse(
                                                  item.description
                                                ).New,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "createCompanyFunnelByCompany"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  item.log_class ===
                                  "createCompanyFunnelByCompany"
                                    ? _c(
                                        "div",
                                        [
                                          _c("ComponentLogsCompanyFunnel", {
                                            attrs: {
                                              data: JSON.parse(
                                                item.description
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "store" ||
                          item.log_class === "activateManualSubscription"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-start" },
                                    [
                                      item.description != "null"
                                        ? _c("span", [
                                            _vm._v(
                                              " " +
                                                _vm._s(item.description) +
                                                " "
                                            ),
                                          ])
                                        : _c("span", [_vm._v(" - ")]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "App\\{closure}"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4 " }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-start " },
                                    [
                                      _c("span", [
                                        _vm._v(
                                          " " + _vm._s(item.description) + " "
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "sendXeroInvoice"
                            ? _c(
                                "v-card",
                                { staticStyle: { overflow: "auto" } },
                                [
                                  _c("div", { staticClass: "pa-4" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start text-h6",
                                      },
                                      [_vm._v(" " + _vm._s(item.title) + " ")]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start mb-2",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.convertTimeZone(
                                                item.created_at
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-start mb-2",
                                      },
                                      [
                                        typeof item.description !== "object"
                                          ? _c(
                                              "ul",
                                              _vm._l(
                                                typeof item.description !==
                                                  "object"
                                                  ? JSON.parse(item.description)
                                                  : item.description,
                                                function (value, index) {
                                                  return _c(
                                                    "div",
                                                    { key: index },
                                                    [
                                                      [
                                                        "id",
                                                        "invoice_link",
                                                        "client_invoice_link",
                                                        "invoice_no",
                                                        "description",
                                                        "total_amount",
                                                        "amount_due",
                                                        "total",
                                                        "tax_amount",
                                                        "company_name",
                                                        "email",
                                                        "attachment_link",
                                                        "cc_email",
                                                      ].includes(index)
                                                        ? _c("li", [
                                                            index ===
                                                            "invoice_link"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " Original invoice "
                                                                  ),
                                                                ])
                                                              : index ===
                                                                "client_invoice_link"
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    " Modified invoice "
                                                                  ),
                                                                ])
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        index.replace(
                                                                          "_",
                                                                          " "
                                                                        )
                                                                      ) +
                                                                      " : "
                                                                  ),
                                                                ]),
                                                            index ===
                                                              "invoice_link" ||
                                                            index ===
                                                              "client_invoice_link" ||
                                                            index ===
                                                              "attachment_link"
                                                              ? _c(
                                                                  "a",
                                                                  {
                                                                    attrs: {
                                                                      target:
                                                                        "_blank",
                                                                      href: value,
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      " View invoice "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c("span", [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        value
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                ]),
                                                          ])
                                                        : _vm._e(),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]),
                                ]
                              )
                            : _vm._e(),
                          item.log_class === "updateSchedulerSubscription"
                            ? _c("v-card", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-start text-h6",
                                  },
                                  [_vm._v(" " + _vm._s(item.title) + " ")]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "d-flex justify-start mb-2" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.convertTimeZone(item.created_at)
                                        ) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c(
                                  "div",
                                  {},
                                  _vm._l(
                                    JSON.parse(item.description),
                                    function (value, index) {
                                      return _c("div", { key: index }, [
                                        _c("li", [
                                          _vm._v(
                                            " " +
                                              _vm._s(index) +
                                              " : " +
                                              _vm._s(value) +
                                              " "
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(JSON.parse(value)) +
                                              " "
                                          ),
                                        ]),
                                      ])
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          item.log_class === "deleteSchedulerSubscription"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4 " }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    {},
                                    _vm._l(
                                      JSON.parse(item.description),
                                      function (value, index) {
                                        return _c("div", { key: index }, [
                                          _c("li", [
                                            _vm._v(
                                              " " +
                                                _vm._s(index) +
                                                " : " +
                                                _vm._s(value) +
                                                " "
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                          item.log_class === "pdfDownloadWebinar"
                            ? _c("v-card", [
                                _c("div", { staticClass: "pa-4 " }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start text-h6",
                                    },
                                    [_vm._v(" " + _vm._s(item.title) + " ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex justify-start mb-2",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.convertTimeZone(item.created_at)
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "d-flex justify-start " },
                                    [
                                      _c(
                                        "a",
                                        {
                                          on: {
                                            click: function ($event) {
                                              return _vm.openNewTabPdf(
                                                item.description
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v(" Download certificate ")]
                                      ),
                                    ]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }