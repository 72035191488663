var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.finance != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [
            _vm._v(" Financial year end ( FYE ) : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.finance.fye) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Revenue (RM'000) "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.finance.revenue != null ? this.finance.revenue : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Profit before tax (RM'000) : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(this.finance.pbt != null ? this.finance.pbt : "-") +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Profit after tax "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(this.finance.pat != null ? this.finance.pat : "-") +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }