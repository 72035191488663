var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.hrdc != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", [
            _vm._v(" HRDC Invoice : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.hrdc.hrdc_invoice != null
                      ? this.hrdc.hrdc_invoice
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Year : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(this.hrdc.year != null ? this.hrdc.year : "-") +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Year(s) of Subscription : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.hrdc.year_of_subscription != null
                      ? this.hrdc.year_of_subscription
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" HRDC Invoice Sent Date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.hrdc.hrdc_invoice_sent_date != null
                      ? _vm.convertTimeZone(this.hrdc.hrdc_invoice_sent_date)
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }