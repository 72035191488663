var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.dropdown != null
      ? _c(
          "div",
          { staticClass: "d-flex flex-column" },
          _vm._l(Object.entries(this.dropdown), function (item, index) {
            return _c("div", { key: index }, [
              !["id", "created_at", "updated_at"].includes(item[0])
                ? _c("div", [
                    _c("span", [_vm._v(" " + _vm._s(item[0]) + " : ")]),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(" " + _vm._s(item[1]) + " "),
                    ]),
                  ])
                : _vm._e(),
            ])
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }