var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.users != null
      ? _c(
          "div",
          { staticClass: "my-3" },
          _vm._l(this.users, function (item, index) {
            return _c(
              "div",
              { key: index, staticClass: "d-flex flex-column" },
              [
                _c("div", [
                  _c("p", { staticClass: "font-weight-bold text-h7 my-5" }, [
                    _vm._v(" User " + _vm._s(index + 1) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Name : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.fname + " " + item.lname) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Email : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.email) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Mobile : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.mobile) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Role : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.role) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Account Type : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.acc_category) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Bonus Offer : "),
                  item.bonus_offer != null
                    ? _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" " + _vm._s(item.bonus_offer) + " "),
                      ])
                    : _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(" - "),
                      ]),
                ]),
                _c("div", [
                  _vm._v(" Referral Points: "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.referral_points) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Existing Client : "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          item.existing_client != null
                            ? item.existing_client
                            : "-"
                        ) +
                        " "
                    ),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Active "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(
                      " " +
                        _vm._s(item.active != null ? item.active : "-") +
                        " "
                    ),
                  ]),
                ]),
                _c("div", [
                  _c("div", [
                    _vm._v(" Customer Service: "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            item.cs_username != null ? item.cs_username : "-"
                          ) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Welcome Guide Sent: "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.welcome_guide_sent) + " "),
                  ]),
                ]),
                _c("div", [
                  _vm._v(" Welcome WA Sent: "),
                  _c("span", { staticClass: "font-weight-bold" }, [
                    _vm._v(" " + _vm._s(item.welcome_wa_sent) + " "),
                  ]),
                ]),
                _c(
                  "div",
                  { staticClass: "my-2" },
                  [
                    _vm._v(" Verified : "),
                    item.email_verified_at != null
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "white--text",
                            attrs: { color: "green " },
                          },
                          [_vm._v(" " + _vm._s("Verified") + " ")]
                        )
                      : _c("span", [
                          _vm._v(" " + _vm._s("Not Verified") + " "),
                        ]),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _vm._v(" Status : "),
                    item.status == 1
                      ? _c(
                          "v-chip",
                          {
                            staticClass: "white--text",
                            attrs: { color: "green" },
                          },
                          [_vm._v(" " + _vm._s("Enabled") + " ")]
                        )
                      : _c("span", [_vm._v(" " + _vm._s("Disabled") + " ")]),
                  ],
                  1
                ),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }