var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.refund != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", {}, [
            _vm._v(" Status : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.refund.refund_status) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Amount (RM) : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.refund.refund_amount != null
                      ? this.refund.refund_amount
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" CN No. : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(this.refund.cn_no != null ? this.refund.cn_no : "-") +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Refund Sent Date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.refund.refund_sent_date != null
                      ? _vm.convertTimeZone(this.refund.refund_sent_date)
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }