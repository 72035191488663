var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-4" }, [
    this.subscriptionHistory != null
      ? _c("div", { staticClass: "d-flex flex-column" }, [
          _c("div", {}, [
            _vm._v(" Status : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(" " + _vm._s(this.subscriptionHistory.status) + " "),
            ]),
          ]),
          _c("div", [
            _vm._v(" Year : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.year != null
                      ? this.subscriptionHistory.year
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Payment date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.payment_date != null
                      ? _vm.convertTimeZone(
                          this.subscriptionHistory.payment_date
                        )
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Subscription start date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.subscription_start_date != null
                      ? _vm.convertTimeZone(
                          this.subscriptionHistory.subscription_start_date
                        )
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Subscription end date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.subscription_end_date != null
                      ? _vm.convertTimeZone(
                          this.subscriptionHistory.subscription_end_date
                        )
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Price (RM) : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.price != null
                      ? this.subscriptionHistory.price
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Offer : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.offer != null
                      ? this.subscriptionHistory.offer
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" PIC : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.pic_salesperson != null
                      ? this.subscriptionHistory.pic_salesperson
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Commision release date : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.commision != null
                      ? _vm.convertTimeZone(this.subscriptionHistory.commision)
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
          _c("div", [
            _vm._v(" Cancellation reason : "),
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                " " +
                  _vm._s(
                    this.subscriptionHistory.cancellation_reason != null
                      ? this.subscriptionHistory.cancellation_reason
                      : "-"
                  ) +
                  " "
              ),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }